.custom-dropdown .country .country-name,
.custom-dropdown .country .country-flag {
 
  width:30px;
}
.custom-dropdown .country .country-iso {
    margin-left: 0; /* Adjust the styling to center the country code */
    width:30px;
  }
  .custom-dropdown .country .country-flag {
    display: none;
  }
  
  .custom-dropdown .country .country-iso {
    display: none;
  }
  .custom-datatable .pagination .react-bootstrap-table-pagination-list .dropdown-toggle::after {
    display: none;
}
.custom-datatable .react-bootstrap-table-pagination .dropdown-toggle.dropdown-toggle-split span {
  display: none;
}
.custom-datatable .react-bootstrap-table-pagination .dropdown-toggle::after {
  content: none;
}
.custom-datatable .react-bootstrap-table-pagination .dropdown-toggle,
.custom-datatable .react-bootstrap-table-pagination .pagination-page-info {
  display: none;
}

