
body {
  line-height: var(--vz-body-line-height);
}

.css-b62m3t-container {
  .css-13cymwt-control {
    background-color: var(--#{$prefix}gray-200);
    border-color: var(--#{$prefix}border-color);

    &:hover {
      border-color: var(--#{$prefix}border-color);
    }
  }

  .css-t3ipsp-control {
    background-color: var(--#{$prefix}gray-200);
    border-color: var(--#{$prefix}border-color);
    box-shadow: none;

    &:hover {
      border-color: var(--#{$prefix}border-color) !important;
    }
  }

  .css-1nmdiq5-menu {
    background-color: var(--#{$prefix}gray-200);

    .css-10wo9uf-option {
      cursor: pointer;
      background-color: var(--#{$prefix}gray-200);

      &:active {
        background-color: var(--#{$prefix}gray-200);
      }
    }

    .css-d7l1ni-option {
      cursor: pointer;
      background-color: var(--#{$prefix}gray-100);
    }
  }

  .css-1p3m7a8-multiValue {
    background-color: var(--#{$variable-prefix}light);
  }

  .css-wsp0cs-MultiValueGeneric {
    color: $heading-color;
  }

  .css-12jo7m5 {
    color: $heading-color;
  }

  .css-6j8wv5-Input,
  .css-qc6sy-singleValue {
    color: $heading-color;
  }

  .css-1dimb5e-singleValue {
    color: var(--vz-body-color);
  }
}

.marketplace-swiper .swiper-button-next,
.marketplace-swiper .swiper-button-prev {
  top: 24px;
}

.marquee {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 22px;
  line-height: 2;
}

.marquee div {
  display: block;
  width: 200%;
  height: 30px;
  position: absolute;
  overflow: hidden;
  animation: marquee 35s linear infinite;
}

.marquee span {
  float: left;
  width: 50%;
}

@keyframes marquee {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

// Data Table

.kVrXuC,
.dwPlXY,
.gKbhqU,
.iSAVrt {
  background-color: $table-bg !important;
  color: $table-color !important;
}

.fyrdjl {
  color: rgba(var(--#{$prefix}body-color-rgb), 0.54) !important;
  fill: rgba(var(--#{$prefix}body-color-rgb), 0.54) !important;
}

.fyrdjl:disabled {
  color: rgba(var(--#{$prefix}body-color-rgb), 0.18) !important;
  fill: rgba(var(--#{$prefix}body-color-rgb), 0.18) !important;
}

.iSAVrt {
  background-color: var(--#{$prefix}card-bg-custom) !important;
  border-bottom-color: $table-border-color !important;
}

.dwPlXY:not(:last-of-type) {
  border-bottom-color: $table-border-color !important;
}

.border_01 {
  border: 1px solid #d0d0d0;
}

.custom-datatable {
  &.datatable-checkbox {

    .rdt_TableCol,
    .rdt_TableCell {
      &:first-child {
        width: 48px;
      }
    }
  }

  .rdt_Table {
    display: table;
    border-collapse: collapse;

    &>div {

      &:nth-child(2) {
        &:not(.rdt_TableBody) {
          min-height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;

        }
      }
    }
  }

  .rdt_TableHead,
  .rdt_TableBody {
    display: table-row-group;

    &>.rdt_TableRow,
    &>.rdt_TableHeadRow {
      display: table-row;

      &>.rdt_TableCol,
      &>.rdt_TableCell {
        display: table-cell;
        white-space: normal;
        padding: .75rem .6rem;
        line-height: var(--vz-body-line-height);
        white-space: nowrap;
        // vertical-align: top;

        &:first-child {
          padding-left: 16px;
        }

        .form-check {
          input[type='checkbox'] {
            width: 15px;
            height: 15px;
          }
        }
      }
    }

  }

  .rdt_TableHeadRow {
    border-top:1px solid var(--#{$prefix}border-color) !important;
    border-bottom:1px solid var(--#{$prefix}border-color) !important;
    background-color: rgba(var(--#{$prefix}dark-rgb), 1) !important;
    color: $white !important;

  }
}

.no-record-message {
  white-space: nowrap;
  min-height: 200px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  width: 100%;
  padding: 17px;
}

.rdt_TableHeader {
  border-radius: var(--vz-card-border-radius) var(--vz-card-border-radius) 0 0;
  padding: var(--vz-card-cap-padding-y) var(--vz-card-cap-padding-x) !important;

  &>div {
    &:last-child {
      display: none !important;
    }
  }
}


.form-control-select {

  &__control,
  &__menu {
    border-color: var(--vz-input-border) !important;
    background-color: var(--vz-input-bg) !important;
  }

  &__control {
    .form-control-select__indicator-separator {
      display: none;
    }

    .form-control-select__indicator {
      svg {
        width: 12px;
        fill: #363d48 !important
      }
    }

    &--is-disabled {
      background-color: rgba(var(--vz-light-rgb), .75) !important;
    }
  }

  &__option {
    background-color: var(--vz-input-bg) !important;

    &--is-selected {
      background-color: $blue !important;
      color: $white;
    }

    &:hover {
      background-color: var(--vz-input-border) !important;
      color: $blue;
    }
  }

}

.date-range-fixed-size {
  @media (min-width: 1200px) {
    width: 234px;
  }
}

.border-color-b9b9b9 {
  border-color: #b9b9b9;
  color: $black;
}

.canvas-right-sidebar {

  .offcanvas-body {
    // height: calc(100vh - 70px);
    // padding-bottom: 135px;
  }
}

.size-99p {
  @media (min-width: 1200px) {
    width: 99%;
  }

}

.border-lght-2 {
  border-color: #adadad;
}

.sidebar-sticky {
  position: sticky;
  top: 90px;
}

.dynamic-pagination {
  .swiper-pagination-bullet {
    width: 8px !important;
    height: 8px !important;
    border-radius: 50%;
  }

  .swiper-pagination-bullet {
    &.swiper-pagination-bullet-active {
      width: 20px !important;
    }
  }
}

.offcanvas {
  &.offcanvas-custom-style {
    height: 100%;

    .offcanvas-body {
      height: calc(100vh - 140px);
    }
  }
}

.input-step {
  .input-number-sidebar {
    @media (min-width: 1200px) {
      width: 6em;
    }
  }
}


.rdt_TableHeader {
  @media (max-width: 767.98px) {
    flex-direction: column;
    padding-right: 16px !important;

    &>* {
      width: 100%;
    }
  }

  .no-paading-mobile {
    @media (max-width: 767.98px) {
      padding: 0;
    }
  }

  &>div {
    @media (max-width: 767.98px) {
      &:nth-child(2) {
        flex-wrap: wrap;
        display: block;
        justify-content: flex-start;

        &>* {
          margin: inherit;
        }
      }
    }

  }

  .hstack {
    @media (max-width:767.98px) {
      flex-direction: column;

    }
  }

  .search-box {
    @media (max-width: 767.98px) {
      width: 100%;
    }
  }

  .w-100-mobile {
    @media (max-width: 767.98px) {
      width: 100%;
    }
  }
}

.new_auth_bg_cover {
  background: linear-gradient(325deg, #000000 40%, #ffb500);
}

.imgCarStyle {
  position: absolute;
  bottom: -30px;
  left: -30px;

  @media (max-width: 1024px) {
    left: 5px;
  }
}

.auth-one-bg_new {
  background-image: url(../../../images/auth-one-bg.jpg);
  background-position: -50px -90px;
  background-size: 200%;
  background-repeat: no-repeat;
}

.auth-page-wrapper .auth-page-content {
  padding-bottom: 60px;
  position: relative;
  z-index: 2;
  width: 100%;
}




.fs-1 {
  font-size: calc(1.33125rem + .975vw) !important
}

.fs-2 {
  font-size: calc(1.29rem + .48vw) !important
}

.fs-3 {
  font-size: calc(1.269375rem + .2325vw) !important
}

.fs-4 {
  font-size: 1.2375rem !important
}

.fs-5 {
  font-size: 1.03125rem !important
}

.fs-6 {
  font-size: .825rem !important
}

.btn-infor-border {
  border-color: $info-border-color;
}

.card {
  &.card-car-info {
    border: 1px solid transparent;
  }

  &.active-card {
    border-color: $primary;
  }
}


.activetab {
  .active {
    background-color: #363d48 !important;
    color: #fff !important;
  }
}

.border_gry {
  border: solid 1px #dddddd;
}
.form-check {
&:not(.form-switch) {
.form-check-input[type="radio"] {
  
    border-radius: 0.25em;

    &:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e");
    }
  }
}
}
.navbar-brand-box {
  border-bottom: 1px solid #222223;
}
.custom-sort-img {
  .custom-sort-icon {
    transition: none !important;
    font-size: 14px !important;
    margin-left: 5px !important;
    width: 15px !important;
    height: 15px !important;
    transform: none !important;
  }
}
.input-group-flag {
  .form-control {
    &.flag-input {
     @media (min-width: 1200px) {
      
      padding-right: .7em;
     }
    }
  }
}
.form-control {
  &.form-control-extra-stop {
    width: calc(100% - 50px);
  }
}
.table-dropdown {
  width: 216px;
}